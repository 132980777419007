import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import BeachAccessRoundedIcon from '@mui/icons-material/BeachAccessRounded';

// const items = [
//   {
//     icon: <ViewQuiltRoundedIcon />,
//     title: 'Сайт',
//     description:
//       'Удобный доступ к ключевым функциям и данным для управления через веб-интерфейс.',
//     imageLight: 'url("https://i.imgur.com/NsXtMFJ.png")',
//     imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
//   },
//   {
//     icon: <EdgesensorHighRoundedIcon />,
//     title: 'Мобильное приложение',
//     description:
//       'Поддержка мобильных устройств для управления и работы через мобильное приложение',
//     imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
//     imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
//   },
//   {
//     icon: <DevicesRoundedIcon />,
//     title: 'Доступен на всех платформах',
//     description:
//       'Наш продукт доступен на всех платформах, обеспечивая гибкость и доступность для ваших сотрудников',
//     imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
//     imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
//   },
// ];

const features = [
  {
    icon: <InsertDriveFileRoundedIcon />,
    title: "Интеграция документооборота",
    description: "Простая и безопасная интеграция кадрового электронного документооборота в структуру компании."
  },
  {
    icon: <AccessTimeRoundedIcon />,
    title: "Электронный табель",
    description: "Электронный табель посещаемости в режиме реального времени."
  },
  {
    icon: <EventRoundedIcon />,
    title: "Рабочий календарь",
    description: "Личный рабочий календарь у каждого сотрудника."
  },
  {
    icon: <SearchRoundedIcon />,
    title: "Поиск специалистов",
    description: "Легкий поиск нужных специалистов внутри компании."
  },
  {
    icon: <BeachAccessRoundedIcon />,
    title: "Оформление отпуска",
    description: "Простое оформление отпуска и подтвержедение руководителем из личного кабинета."
  }
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
  };

  // const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <div>
        <Typography component="h2" variant="h4" color="text.primary" gutterBottom>
          Особенности продукта
        </Typography>
        <Grid container spacing={4} sx={{ mb: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1}}>
                    {feature.icon}
                  </Box>
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      {/* <Typography component="h1" variant='h4' color="text.primary" sx={{mb:2}}>
        Доступность
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) =>
                    theme.palette.mode === 'light' && selectedItemIndex === index ? 'primary.light' : '',
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  borderRadius: 2,
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : 'background.paper',
                  boxShadow: selectedItemIndex === index ? 3 : 1,
                  borderColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200'
                      : selectedItemIndex === index
                        ? 'primary.dark'
                        : 'grey.800',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === 'light' && selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.300',
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
              boxShadow: 3,
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid> */}
    </Container>
  );
}
