import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logoLight from '../assets/wd-icon-2.svg';
import logoDark from '../assets/wd-icon.svg';

const logoStyle = {
  width: '140px',
  height: 'auto',
  marginLeft: '20px', 
};

export default function Footer() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Use media query to detect the theme mode if you want to support system theme
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // const logo = prefersDarkMode ? logoDark : logoLight;

  const logo = isDarkMode ? logoDark : logoLight;

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: '-15px' }}>
              <img
                src={logo}
                style={logoStyle}
                alt="logo of sitemark"
              />
            </Box>
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Продукт
          </Typography>
          <Link color="text.secondary" href="#">
            Особенности
          </Link>
          <Link color="text.secondary" href="#">
            Отзывы
          </Link>
          <Link color="text.secondary" href="#">
            Ключевые моменты
          </Link>
          <Link color="text.secondary" href="#">
            FAQs
          </Link>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="#">
            Политика конфиденциальности
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Условия обслуживания
          </Link>
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://mail.google.com/mail/?view=cm&fs=1&to=workingday.info@gmail.com"
            target="_blank"
            aria-label="Email"
            sx={{ alignSelf: 'center' }}
          >
            <MailOutlineIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
