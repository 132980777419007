import React, { useState, useEffect, ChangeEvent } from 'react';
import { alpha, useTheme, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import screen1 from '../assets/Экран-1.jpg';
import screen2 from '../assets/Экран-2.jpg'

const images = [
  'https://i.imgur.com/8TsI47E.png',
  'https://i.imgur.com/MC2S8Ic.png',
  'https://i.imgur.com/VuNkjqZ.png',
  'https://i.imgur.com/pFCXW0q.png',
  'https://i.imgur.com/RoxR7VS.png',
  // 'https://i.imgur.com/dksFPv4.png',
  'https://i.imgur.com/82iBVFp.png',
];

const Hero: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [fadeProp, setFadeProp] = useState<string>('fade-in');
  const [email, setEmail] = useState<string>('');
  const theme = useTheme<Theme>();

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 8000); 

    return () => clearInterval(interval); 
  }, []);

  const nextImage = () => {
    setFadeProp('fade-out');
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setFadeProp('fade-in');
    }, 500);
  };

  const prevImage = () => {
    setFadeProp('fade-out');
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setFadeProp('fade-in');
    }, 500);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    if (!email) {
      alert("Пожалуйста, введите адрес электронной почты.");
      return;
    }

    try {
      const response = await fetch('https://xn----7sbdnfph9adr4f2c.xn--p1ai/save-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        alert("Ваш адрес электронной почты успешно сохранен!");
      } else {
        alert("Ошибка при сохранении адреса электронной почты.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Произошла ошибка при отправке данных.");
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Рабочий&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              День
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Трансформируйте свои бизнес-процессы с платформой Рабочий День <br />
            Ведите кадровый электронный документооборот, контролируйте посещаемость и развивайте коммуникацию между сотрудниками с нашей платформой
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Ваш адрес email"
              value={email}
              onChange={handleEmailChange}
              inputProps={{
                autoComplete: 'off',
                'aria-label': 'Enter your email address',
              }}
            />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSubmit}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText, 
              backgroundImage: 'none', 
              border: 'none',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark, 
                color: theme.palette.primary.contrastText, 
                backgroundImage: 'none',
                border: 'none', 
                boxShadow: 'none',
              },
            }}
          >
            Получить демо
          </Button>
          </Stack>
          {/* <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            Нажав кнопку «Отправить» вы соглашаетесь с нашими&nbsp;
            <Link href="#" color="primary">
              Условиями использования
            </Link>
            .
          </Typography> */}
        </Stack>
        <Box
          id="image"
          className={fadeProp}
          sx={{
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 190, sm: 530},
            width: '100%',
            backgroundImage: `url(${images[currentImageIndex]})`,
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
            transition: 'opacity 0.5s ease-in-out',
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              left: 'calc(0% - 50px)',
              transform: 'translateY(-50%)',
              color: theme.palette.mode === 'light' ? 'darkblue' : '#ffffff',
              zIndex: 10,
            }}
            onClick={prevImage}
          >
            <ArrowBackIosNewIcon fontSize="large" />
          </IconButton>
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              right: 'calc(0% - 50px)',
              transform: 'translateY(-50%)',
              color: theme.palette.mode === 'light' ? 'darkblue' : '#ffffff',
              zIndex: 10,
            }}
            onClick={nextImage}
          >
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
          <Box
            sx={{
              position: 'absolute',
              bottom: -30,
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {images.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor:
                    theme.palette.mode === 'light'
                      ? index === currentImageIndex ? 'darkblue' : '#B2B2B2'
                      : index === currentImageIndex ? '#ffffff' : 'darkblue',
                  transition: 'background-color 0.3s',
                }}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Hero;
